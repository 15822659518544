import React, { useCallback } from 'react';
import { FocusLayerSizeV2 } from '@volkswagen-onehub/layer-manager';
import { LayerContentHTML } from '@volkswagen-onehub/oneaudi-one-layer/dist/utils';
import { HeaderStyledNavItem, HeaderStyledNavItemLink } from './AudiHeaderNavItemStyles';
import {
  createResetActiveItemAction,
  createSetActiveItemAction,
} from '../../actions/header-actions';
import { getClassNamesForLinks, getLinkTarget } from '../../services';

import { AudiHeaderNavItemProps } from '../../interfaces/header-components.interfaces';
import AudiHeaderSubNav from '../AudiHeaderSubNav/AudiHeaderSubNav';
import { useStateSwitchTimeOutHook } from '../../services/hooks';

const AudiHeaderNavItem: React.FC<AudiHeaderNavItemProps> = ({
  audiMarketContextService,
  headerDispatch,
  headerNavigationItemsAmount,
  headerState,
  headerStateService,
  index,
  item,
  layerManager,
  useFootnoteReferenceServiceTextParserHook,
  useOneLayer,
}) => {
  const { stateSwitch: addClosingAnimation } = useStateSwitchTimeOutHook(440);

  const dangerousHTML = useFootnoteReferenceServiceTextParserHook(
    item.Link.Text.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, 'illegalscript')
  );

  const [defaultHandlerAttached, setDefaultHandlerAttached] = React.useState<boolean>(false);
  const defaultPreventAnchorClickHandler = React.useCallback(
    (e) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (item.SubNavigation?.length > 0) {
        e.preventDefault();
      }
    },
    [item]
  );

  const toggleSubNavigation: (event: any) => void = (event) => {
    event.preventDefault();
    onMenuItemClick(event, item.Link);
    if (index !== headerState.activeItem.index) {
      headerDispatch(
        createSetActiveItemAction({
          anchor: event.currentTarget,
          headerStateService,
          index,
          showSearch: false,
        })
      );
    } else {
      headerDispatch(createResetActiveItemAction(headerStateService));
      addClosingAnimation();
    }
  };

  const onMenuItemClick = useCallback(
    (event, listItem): void => {
      if (
        listItem.Target === '_layer' &&
        listItem.Type === 'default' &&
        layerManager &&
        useOneLayer
      ) {
        event.preventDefault();
        event.stopPropagation();

        layerManager.openFocusLayer(
          () => (
            <LayerContentHTML
              audiMarketContextService={audiMarketContextService}
              fefaStyleConfig={{
                omitBottomSpacing: 'true',
                omitSideSpacing: 'true',
                uiTheme: 'light',
              }}
              url={listItem.Url}
            />
          ),
          {},
          {
            size: FocusLayerSizeV2.A,
            userCloseable: true,
          }
        );
      }
    },
    [audiMarketContextService, layerManager, useOneLayer]
  );
  // this implementation is about adding a new event handler which
  // is called before the original react handler. When integrating
  // GTM the GTM is executed before the React-event for toggleing the submenu.
  // This leads to the effect that otherwise the default
  // behavior of the link is reactivated.
  const anchorElement = React.useRef(null);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line consistent-return
  React.useLayoutEffect(() => {
    if (anchorElement && anchorElement.current) {
      const localAnchorElement: HTMLElement = anchorElement.current;
      if (!defaultHandlerAttached) {
        localAnchorElement.addEventListener('click', defaultPreventAnchorClickHandler);
        setDefaultHandlerAttached(true);

        return (): void => {
          localAnchorElement.removeEventListener('click', defaultPreventAnchorClickHandler);
        };
      }
    }
  }, [item, defaultHandlerAttached, defaultPreventAnchorClickHandler]);

  const onMainMenuClick = useCallback(
    (event) => {
      onMenuItemClick(event, item.Link);
    },
    [item.Link, onMenuItemClick]
  );

  const closeSubNavigation: (event: any) => void = (event) => {
    event.preventDefault();
    headerDispatch(createResetActiveItemAction(headerStateService));
  };

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {item.SubNavigation?.length > 0 ? (
        <HeaderStyledNavItem headerNavigationItemsAmount={headerNavigationItemsAmount}>
          <HeaderStyledNavItemLink
            className={getClassNamesForLinks(item.Link, useOneLayer)}
            data-testid="one-header-nav-item-link"
            headerNavigationItemsAmount={headerNavigationItemsAmount}
            href={item.Link.Url}
            onClick={toggleSubNavigation}
            ref={anchorElement}
            target={getLinkTarget(item.Link)}
            tabIndex={0}
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: dangerousHTML,
              }}
            />
            <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 16.9l5.5-5.4L10 6.1" fill="none" stroke="currentColor" />
            </svg>
          </HeaderStyledNavItemLink>
          <AudiHeaderSubNav
            closeSubNavigation={closeSubNavigation}
            headerNavigationItemsAmount={headerNavigationItemsAmount}
            isSubNavOpen={headerState.activeItem.index === index}
            onItemClick={onMenuItemClick}
            subNavItem={item}
            useFootnoteReferenceServiceTextParserHook={useFootnoteReferenceServiceTextParserHook}
            useOneLayer={useOneLayer}
          />
        </HeaderStyledNavItem>
      ) : (
        <HeaderStyledNavItem headerNavigationItemsAmount={headerNavigationItemsAmount}>
          <HeaderStyledNavItemLink
            className={getClassNamesForLinks(item.Link, useOneLayer)}
            data-testid="one-header-nav-item-link"
            headerNavigationItemsAmount={headerNavigationItemsAmount}
            href={item.Link.Url}
            onClick={onMainMenuClick}
            target={item.Link.Target}
            tabIndex={0}
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: dangerousHTML,
              }}
            />
          </HeaderStyledNavItemLink>
        </HeaderStyledNavItem>
      )}
    </>
  );
};

export default AudiHeaderNavItem;
