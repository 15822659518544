import React from 'react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { responsiveStyles } from '@audi/audi-ui-react-v2';
import { AudiCopy1WithHoverStyle, timeL, timeM, timeXL } from '../AudiHeader/AudiHeaderStyleUtils';
import {
  AudiHeaderMenuButtonProps,
  MenuIconAnimatedLinesProps,
} from '../../interfaces/header-components.interfaces';
import {
  iconBottomCloseKeyframes,
  iconBottomOpenKeyframes,
  iconMiddleCloseKeyframes,
  iconMiddleOpenKeyframes,
  iconMiddleSecondCloseKeyframes,
  iconMiddleSecondOpenKeyframes,
  iconTopCloseKeyframes,
  iconTopOpenKeyframes,
} from './AudiHeaderMenuButtonKeyframes';
import { HeaderStyledMenuButtonWrapperProps } from '../../interfaces/header-styled-components.interfaces';
import { createBreakpointStyles } from '../../services/index';

const headerStyledMenuButtonStyles = (): Array<{
  maxNavItems: number;
  style: unknown;
}> => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      display: {
        l: 'none',
      },
    },
  },
  {
    maxNavItems: 5,
    style: {
      display: {
        xl: 'none',
      },
    },
  },
  {
    maxNavItems: 8,
    style: {
      display: {
        xxl: 'none',
      },
    },
  },
];

const HeaderStyledMenuButtonWrapper = styled.div<HeaderStyledMenuButtonWrapperProps>`
  display: ${({ isLoading }): string => (isLoading ? 'none' : 'block')};
  flex-shrink: 0;
  ${({ headerNavigationItemsAmount, theme }): string =>
    responsiveStyles(
      createBreakpointStyles(headerNavigationItemsAmount, headerStyledMenuButtonStyles()),
      theme.breakpoints
    )};
  order: -1;
  position: relative;
  width: 33%;

  & button {
    background-color: transparent;
    border: 0 none;
    cursor: pointer;
    display: flex;
    font-size: 1em;
    margin: var(--one-header-space-l) 0;
    order: 1;
    padding: 0;
    text-align: left;
  }
`;

const StyledMenuButtonIcon = styled.div`
  height: 18px;
  margin: 3px var(--one-header-space-s) 3px 3px;
  position: relative;
  width: 18px;
`;

const StyledMenuButtonIconTopLine = styled.div<MenuIconAnimatedLinesProps>`
  background-color: var(--one-header-color-black);
  height: 1px;
  left: 0;
  ${({ isOpen, theme }): FlattenSimpleInterpolation =>
    isOpen
      ? css`
          animation: ${iconTopOpenKeyframes} ${timeXL} forwards;
          animation-timing-function: ${theme.easing};
        `
      : css`
          animation: ${iconTopCloseKeyframes} ${timeXL};
        `}
  position: absolute;
  right: 0;
  top: 6px;
`;

const StyledMenuButtonIconMiddleLine = styled.div<MenuIconAnimatedLinesProps>`
  background-color: var(--one-header-color-black);
  height: 1px;
  left: 0;
  ${({ isOpen, theme }): FlattenSimpleInterpolation =>
    isOpen
      ? css`
          animation: ${iconMiddleOpenKeyframes} ${timeL} forwards;
          animation-delay: ${timeM};
          animation-timing-function: ${theme.easing};
        `
      : css`
          animation: ${iconMiddleCloseKeyframes} ${timeL};
        `}
  position: absolute;
  right: 0;
  top: 9px;
`;

const StyledMenuButtonIconMiddleSecondLine = styled.div<MenuIconAnimatedLinesProps>`
  background-color: var(--one-header-color-black);
  height: 1px;
  left: 0;
  ${({ isOpen, theme }): FlattenSimpleInterpolation =>
    isOpen
      ? css`
          animation: ${iconMiddleSecondOpenKeyframes} ${timeL} forwards;
          animation-delay: ${timeM};
          animation-timing-function: ${theme.easing};
        `
      : css`
          animation: ${iconMiddleSecondCloseKeyframes} ${timeL};
        `}
  position: absolute;
  right: 0;
  top: 9px;
`;

const StyledMenuButtonIconBottomLine = styled.div<MenuIconAnimatedLinesProps>`
  background-color: var(--one-header-color-black);
  height: 1px;
  left: 0;
  ${({ isOpen, theme }): FlattenSimpleInterpolation =>
    isOpen
      ? css`
          animation: ${iconBottomOpenKeyframes} ${timeXL} forwards;
          animation-timing-function: ${theme.easing};
        `
      : css`
          animation: ${iconBottomCloseKeyframes} ${timeXL};
        `}
  position: absolute;
  right: 0;
  top: 12px;
`;

const StyledMenuButtonText = styled.span`
  color: var(--one-header-color-black);
  ${({ theme }): FlattenSimpleInterpolation => AudiCopy1WithHoverStyle(theme)};
`;

const AudiHeaderMenuButton: React.FC<AudiHeaderMenuButtonProps> = ({
  label,
  headerNavigationItemsAmount,
  onClick,
  isLoading,
  isOpen,
}) => {
  return (
    <HeaderStyledMenuButtonWrapper
      headerNavigationItemsAmount={headerNavigationItemsAmount}
      isLoading={isLoading}
      isOpen={isOpen}
    >
      <button aria-label={label} onClick={onClick} type="button">
        <StyledMenuButtonIcon>
          <StyledMenuButtonIconTopLine isOpen={isOpen} />
          <StyledMenuButtonIconMiddleLine isOpen={isOpen} />
          <StyledMenuButtonIconMiddleSecondLine isOpen={isOpen} />
          <StyledMenuButtonIconBottomLine isOpen={isOpen} />
        </StyledMenuButtonIcon>
        <StyledMenuButtonText>{label}</StyledMenuButtonText>
      </button>
    </HeaderStyledMenuButtonWrapper>
  );
};

export default AudiHeaderMenuButton;
