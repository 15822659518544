/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureServices } from '@feature-hub/core';
import type { Logger } from '@feature-hub/logger';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import {
  AudiFootnoteReferenceServiceInterfaceV3,
  AudiFootnoteRefernceServiceScopeManagerInterfaceV3,
} from '@volkswagen-onehub/audi-footnote-reference-service';
import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import type { AudiHeaderStateServiceInterfaceV1 } from '@volkswagen-onehub/audi-header-state-service';
import type { AudiMarketContextServiceV2 } from '@volkswagen-onehub/audi-market-context-service';
import {
  SearchManagerServiceV5,
  defineSearchManagerService,
} from '@volkswagen-onehub/audi-search-manager-service';
import type { LayerManagerV25 } from '@volkswagen-onehub/layer-manager';
import type { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import type { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { AudiPlatformProvider } from '@audi/audi-ui-react-v2';
import I18nContextComponent from '@volkswagen-onehub/audi-i18n-context';
import type { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { ReactFeatureApp } from '@feature-hub/react';
import type HeaderResponse from './interfaces/header-response.interfaces';
import FeatureApp from './FeatureApp';
import { fetchHeaderConfig, initialHeaderConfig } from './services/navigation-service';
import { cleanNavigationEntries } from './utils/clean-navigation-entries';
import { AudiHeaderConfig } from './interfaces/header-components.interfaces';

// Type to work with the microkenrel that maybe is available globaly
declare global {
  interface Window {
    microkernel: {
      stateRegistry: {
        // eslint-disable-next-line max-len
        subscribeToStore: (
          name: string,
          callback: (state: React.SetStateAction<undefined>) => void
        ) => void;
        // eslint-disable-next-line max-len
        unsubscribeFromStore: (
          name: string,
          callback: (state: React.SetStateAction<undefined>) => void
        ) => void;
      };
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    SETUPS: {
      get(arg0: string): string;
    };
  }
}

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'gfa:service-config-provider': GfaServiceConfigProviderV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'audi-header-state-service': AudiHeaderStateServiceInterfaceV1;
  readonly 'audi-market-context-service': AudiMarketContextServiceV2;
  readonly 's2:async-ssr-manager': AsyncSsrManagerV1 | undefined;
  readonly 's2:serialized-state-manager': SerializedStateManagerV1 | undefined;
  readonly 'search-manager-service': SearchManagerServiceV5;
  readonly 'layer-manager': LayerManagerV25;
}

type FeatureHubAppDefinitionType = FeatureAppDefinition<
  ReactFeatureApp,
  FeatureServiceDependencies,
  AudiHeaderConfig
>;

const featureAppDefinition: FeatureHubAppDefinitionType = {
  create: ({ featureServices, config, featureAppId }) => {
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');

    const headerConfigDataUrl = config?.navigationServiceUrl;
    const enablePartnerIdReplacement = config?.enablePartnerIdReplacement || false;
    const serializedStateManager = featureServices['s2:serialized-state-manager'];
    const asyncSsrManager = featureServices['s2:async-ssr-manager'];
    const headerStateService = featureServices['audi-header-state-service'];
    const layerManager = featureServices['layer-manager'];
    const audiMarketContextService = featureServices['audi-market-context-service'];
    const i18nData: I18NServiceV1 = featureServices['dbad:audi-i18n-service'];
    const referenceServiceScopeManager = featureServices[
      'audi-footnote-reference-service'
    ] as AudiFootnoteRefernceServiceScopeManagerInterfaceV3;

    const referenceServiceManager: AudiFootnoteReferenceServiceInterfaceV3 =
      referenceServiceScopeManager.getDefaultScopeRefService();

    let headerData: HeaderResponse = initialHeaderConfig;

    if (asyncSsrManager && headerConfigDataUrl) {
      if (typeof serializedStateManager !== 'undefined') {
        serializedStateManager.register(() => JSON.stringify(headerData));
      }
      asyncSsrManager.scheduleRerender(
        (async (): Promise<HeaderResponse> => {
          await fetchHeaderConfig(headerConfigDataUrl).then((response) => {
            headerData = cleanNavigationEntries(response);
          });
          return headerData;
        })()
      );
    } else {
      const serializedAudiHeaderData = serializedStateManager?.getSerializedState();
      if (serializedAudiHeaderData) {
        headerData = JSON.parse(serializedAudiHeaderData);
      }
    }

    return {
      render: (): React.ReactNode => (
        <AudiPlatformProvider>
          <I18nContextComponent
            featureServices={featureServices}
            i18nData={i18nData}
            scopes={['nemo.common', 'fa.audi-basket']}
          >
            <FeatureApp
              audiMarketContextService={audiMarketContextService}
              config={config}
              enablePartnerIdReplacement={enablePartnerIdReplacement}
              featureAppId={featureAppId}
              featureServices={featureServices}
              headerConfigDataUrl={headerConfigDataUrl}
              headerStateService={headerStateService}
              layerManager={layerManager}
              referenceServiceManager={referenceServiceManager}
              ssrHeaderData={headerData}
            />
          </I18nContextComponent>
        </AudiPlatformProvider>
      ),
    };
  },
  dependencies: {
    externals: {
      '@audi/audi-ui-react-v2': '^2',
      '@feature-hub/react': '^2.9.0',
      react: '^16.13.1 || ^17.0.2',
      'react-dom': '^16.13.1 || ^17.0.2',
      'styled-components': '^5.1.1',
    },
    featureServices: {
      'audi-header-state-service': '1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'layer-manager': '2.4.0',
      'locale-service': '1.1.0',
      'search-manager-service': '5.0.0',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-footnote-reference-service': '3.0.0',
      'audi-market-context-service': '^2.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:logger': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
    },
  },
  ownFeatureServiceDefinitions: [defineSearchManagerService()],
};

export default featureAppDefinition;
