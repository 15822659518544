import { responsiveStyles } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import {
  HeaderStyledNavProps,
  HeaderStyledNavWrapperProps,
} from '../../interfaces/header-styled-components.interfaces';
import {
  generateMobileMenuMaxHeightCss,
  timeL,
  timeM,
  timeXL,
} from '../AudiHeader/AudiHeaderStyleUtils';
import { createBreakpointStyles } from '../../services/index';

const headerStyledNavWrapperStyles = (): Array<{
  maxNavItems: number;
  style: unknown;
}> => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      height: { l: 'var(--one-header-height)' },
      overflow: { l: 'initial' },
      position: { l: 'static' },
      top: { l: '0' },
      'z-index': { l: '1' },
    },
  },
  {
    maxNavItems: 5,
    style: {
      height: { xl: 'var(--one-header-height)' },
      overflow: { xl: 'initial' },
      position: { xl: 'static' },
      top: { xl: '0' },
      'z-index': { xl: '1' },
    },
  },
  {
    maxNavItems: 8,
    style: {
      height: { xxl: 'var(--one-header-height)' },
      overflow: { xxl: 'initial' },
      position: { xxl: 'static' },
      top: { xxl: '0' },
      'z-index': { xxl: '1' },
    },
  },
];

export const HeaderStyledNavWrapper = styled.div<HeaderStyledNavWrapperProps>`
  background-color: var(--one-header-color-white);
  display: block;
  height: ${({ isOpen }): string => (isOpen ? generateMobileMenuMaxHeightCss() : '0')};
  left: 0;
  ${({ headerNavigationItemsAmount, theme }): string =>
    responsiveStyles(
      createBreakpointStyles(headerNavigationItemsAmount, headerStyledNavWrapperStyles()),
      theme.breakpoints
    )}
  opacity: 1;
  overflow-x: hidden;
  overflow-y: ${({ isOpen, isSubNavOpen }): string =>
    isOpen && !isSubNavOpen ? 'auto' : 'hidden'};
  position: absolute;
  top: var(--one-header-height);
  transition: height ${({ theme }): string => theme.easing} ${timeXL};
  width: 100%;
  z-index: ${({ showSearch }): string => (showSearch ? '-1' : '1')};
`;

const headerStyledNavStyles = (): Array<{
  maxNavItems: number;
  style: unknown;
}> => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      display: { l: 'flex' },
      'flex-wrap': { l: 'nowrap' },
      height: { l: 'auto' },
      'margin-right': { l: 'var(--one-header-space-xl)' },
      opacity: { l: '1' },
      padding: { l: '0' },
      transform: { l: 'none' },
      transition: { l: 'none' },
    },
  },
  {
    maxNavItems: 5,
    style: {
      display: { xl: 'flex' },
      'flex-wrap': { xl: 'nowrap' },
      height: { xl: 'auto' },
      'margin-right': { xl: 'var(--one-header-space-xl)' },
      opacity: { xl: '1' },
      padding: { xl: '0' },
      position: { xl: 'static' },
      top: { xl: '0' },
      transform: { xl: 'none' },
      transition: { xl: 'none' },
      'z-index': { xl: '1' },
    },
  },
  {
    maxNavItems: 8,
    style: {
      display: { xxl: 'flex' },
      'flex-wrap': { xxl: 'nowrap' },
      height: { xxl: 'auto' },
      'margin-right': { xxl: 'var(--one-header-space-xl)' },
      opacity: { xxl: '1' },
      padding: { xxl: '0' },
      transform: { xxl: 'none' },
      transition: { xxl: 'none' },
    },
  },
  {
    maxNavItems: -1, // > 8 navItems
    style: null,
  },
];

export const HeaderStyledNav = styled.nav<HeaderStyledNavProps>`
  height: 100%;
  ${({ headerNavigationItemsAmount, theme }): string =>
    responsiveStyles(
      createBreakpointStyles(headerNavigationItemsAmount, headerStyledNavStyles()),
      theme.breakpoints
    )}
  opacity: ${({ isOpen }): string => (isOpen ? '1' : '0')};
  padding: calc(var(--one-header-space-xxl) - var(--one-header-space-l) / 2)
    var(--one-header-side-spacing);
  transform: ${({ isOpen }): string => (isOpen ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: top;
  transition: ${({ isOpen, theme }): string =>
    isOpen
      ? `opacity ${theme.easing} ${timeL} ${timeM}, transform ${theme.easing} ${timeXL}`
      : `opacity ${theme.easing} ${timeM}, transform ${theme.easing} ${timeXL}`};
`;
