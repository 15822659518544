import React from 'react';
import { FeatureAppLoader } from '@feature-hub/react';
import { responsiveStyles } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import AppLoaderErrorBoundary from '../AppLoaderErrorBoundary/AppLoaderErrorBoundary';
import { AudiHeaderLoginProps } from '../../interfaces/header-components.interfaces';
import { HeaderStyledLoginProps } from '../../interfaces/header-styled-components.interfaces';
import { createBreakpointStyles } from '../../services/index';

const headerStyledLoginStyles = (): Array<{
  maxNavItems: number;
  style: unknown;
}> => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      display: {
        l: 'block',
      },
    },
  },
  {
    maxNavItems: 5,
    style: {
      display: {
        xl: 'block',
      },
    },
  },
  {
    maxNavItems: 8,
    style: {
      display: {
        xxl: 'block',
      },
    },
  },
];

const HeaderStyledLogin = styled.div<HeaderStyledLoginProps>`
  display: ${({ isOpen }): string => (isOpen ? 'none' : 'block')};
  height: 100%;
  ${({ headerNavigationItemsAmount, theme }): string =>
    responsiveStyles(
      createBreakpointStyles(headerNavigationItemsAmount, headerStyledLoginStyles()),
      theme.breakpoints
    )}
  z-index: 2;
`;

const AudiHeaderLogin: React.FC<AudiHeaderLoginProps> = ({
  headerNavigationItemsAmount,
  isOpen,
  loginFeatureAppUrl,
}) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AppLoaderErrorBoundary>
      <HeaderStyledLogin headerNavigationItemsAmount={headerNavigationItemsAmount} isOpen={isOpen}>
        <FeatureAppLoader featureAppId="header-login" src={loginFeatureAppUrl} />
      </HeaderStyledLogin>
    </AppLoaderErrorBoundary>
  );
};

export default AudiHeaderLogin;
