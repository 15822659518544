import React from 'react';

import { HeaderStyledNav, HeaderStyledNavWrapper } from './AudiHeaderNavStyles';
import AudiHeaderActiveBar from '../AudiHeaderActiveBar/AudiHeaderActiveBar';
import AudiHeaderNavItem from '../AudiHeaderNavItem/AudiHeaderNavItem';
import { AudiHeaderNavProps } from '../../interfaces/header-components.interfaces';

const AudiHeaderNav: React.FC<AudiHeaderNavProps> = ({
  audiMarketContextService,
  headerNavigationItemsAmount,
  navigation,
  headerDispatch,
  headerState,
  headerStateService,
  navigationElement,
  isClosing,
  isOpen,
  useFootnoteReferenceServiceTextParserHook,
  useOneLayer,
  windowWidth,
  layerManager,
}) => {
  return (
    <HeaderStyledNavWrapper
      headerNavigationItemsAmount={headerNavigationItemsAmount}
      isOpen={isOpen}
      isSubNavOpen={headerState.activeItem.index !== -1}
      ref={navigationElement}
      showSearch={headerState.activeItem.showSearch}
    >
      <HeaderStyledNav
        aria-label="Main"
        headerNavigationItemsAmount={headerNavigationItemsAmount}
        isOpen={isOpen}
        isSubNavOpen={headerState.activeItem.index !== -1}
        role="navigation"
      >
        {navigation?.map((navigationItem, index) => {
          const link = navigationItem.Link;
          const key = `${link.Text}${link.Url}`;

          return (
            <AudiHeaderNavItem
              audiMarketContextService={audiMarketContextService}
              headerDispatch={headerDispatch}
              headerNavigationItemsAmount={headerNavigationItemsAmount}
              headerState={headerState}
              headerStateService={headerStateService}
              index={index}
              isClosing={isClosing}
              isSubNavOpen={headerState.activeItem.index !== -1}
              item={navigationItem}
              key={key}
              layerManager={layerManager}
              useFootnoteReferenceServiceTextParserHook={useFootnoteReferenceServiceTextParserHook}
              useOneLayer={useOneLayer}
            />
          );
        })}
      </HeaderStyledNav>
      <AudiHeaderActiveBar
        headerNavigationItemsAmount={headerNavigationItemsAmount}
        headerState={headerState}
        windowWidth={windowWidth}
      />
    </HeaderStyledNavWrapper>
  );
};

export default AudiHeaderNav;
