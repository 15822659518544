import React from 'react';
import { responsiveStyles } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { createPreloadAction, createSetActiveItemAction } from '../../actions/header-actions';
import { AudiHeaderSearchButtonProps } from '../../interfaces/header-components.interfaces';
import { HeaderStyledSearchButtonProps } from '../../interfaces/header-styled-components.interfaces';
import { createBreakpointStyles } from '../../services/index';

/* check syntax!!! */
const headerStyledSearchButtonStyles = (): Array<{
  maxNavItems: number;
  style: unknown;
}> => [
  {
    maxNavItems: 0,
    style: {
      'margin-right': {
        l: 'var(--one-header-space-l)',
      },
    },
  },
  {
    maxNavItems: 4,
    style: {
      display: { l: 'block' },
      'margin-right': {
        l: 'var(--one-header-space-l)',
      },
    },
  },
  {
    maxNavItems: 5,
    style: {
      display: { xl: 'block' },
      'margin-right': {
        xl: 'var(--one-header-space-l)',
      },
    },
  },
  {
    maxNavItems: 8,
    style: {
      display: { xxl: 'block' },
      'margin-right': {
        xxl: 'var(--one-header-space-l)',
      },
    },
  },
  {
    maxNavItems: -1, // > 8 navItems
    style: null,
  },
];

const HeaderStyledSearchButton = styled.div<HeaderStyledSearchButtonProps>`
  display: ${({ isOpen }): string => (isOpen ? 'block' : 'none')};
  margin: var(--one-header-space-l) 0;
  ${({ headerNavigationItemsAmount, theme }): string =>
    responsiveStyles(
      createBreakpointStyles(headerNavigationItemsAmount, headerStyledSearchButtonStyles()),
      theme.breakpoints
    )}

  & button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
  }

  & button:focus {
    outline: none;
  }

  & button:focus-visible {
    outline: auto;
  }

  & button svg {
    color: var(--one-header-color-black);
    height: 24px;
    width: 24px;
  }
`;

const AudiHeaderSearchButton: React.FC<AudiHeaderSearchButtonProps> = (props) => {
  const { headerDispatch, headerNavigationItemsAmount, headerStateService, isOpen } = props;

  const handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void = (event) => {
    event.preventDefault();
    headerDispatch(
      createSetActiveItemAction({
        anchor: null,
        headerStateService,
        index: -1,
        showSearch: true,
      })
    );
  };

  const handleFocusEvent: (
    event:
      | React.FocusEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) => void = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headerDispatch(createPreloadAction());
  };

  const SearchAriaLabel = `${useI18n({
    defaultMessage: 'Search',
    id: 'nemo.ui.common.search-button',
  })}`;

  return (
    <HeaderStyledSearchButton
      headerNavigationItemsAmount={headerNavigationItemsAmount}
      isOpen={isOpen}
    >
      <button
        aria-label={SearchAriaLabel}
        onClick={handleClick}
        onFocus={handleFocusEvent}
        onMouseEnter={handleFocusEvent}
        onTouchStart={handleFocusEvent}
        type="button"
      >
        <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22 22l-8.3-8.3L22 22zM15.5 9c.1 3.5-2.6 6.4-6.1 6.5H9c-3.6 0-6.5-2.9-6.5-6.5S5.4 2.5 9 2.5s6.5 2.9 6.5 6.5c0 0 0 0 0 0z"
            fill="none"
            stroke="currentColor"
          />
        </svg>
      </button>
    </HeaderStyledSearchButton>
  );
};

export default AudiHeaderSearchButton;
