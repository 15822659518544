import {
  INavigationSearchResponse,
  SearchManagerServiceV5,
} from '@volkswagen-onehub/audi-search-manager-service/dist/interfaces';
import React, { useEffect, useReducer, useState } from 'react';
import { EnumerableFootnote } from '@volkswagen-onehub/audi-footnote-reference-service';
import { SearchEventType } from '@volkswagen-onehub/audi-search-manager-service/dist/enums';
import { HeaderState, headerReducer } from '../../reducers/header-reducer';
import AudiHeader from './AudiHeader';
import { AudiHeaderDataManagerProps } from '../../interfaces/header-components.interfaces';
import { ExternalDependenciesType } from '../../models/ExternalFaDependencies';
import HeaderResponse from '../../interfaces/header-response.interfaces';
import { IHeaderAction } from '../../actions/header-actions';
import { cleanNavigationEntries } from '../../utils/clean-navigation-entries';
import { createUseFootnoteReferenceServiceTextParserHook } from '../../services/hooks';
import { fetchHeaderConfig } from '../../services/navigation-service';
import { replacePartnerId } from '../../utils/replace-partner';

// eslint-disable-next-line max-statements
const AudiHeaderDataManager: React.FC<AudiHeaderDataManagerProps> = ({
  audiMarketContextService,
  config,
  ssrHeaderData,
  headerConfigDataUrl,
  featureServices,
  headerStateService,
  featureAppId,
  referenceServiceManager,
  layerManager,
  enablePartnerIdReplacement,
}) => {
  const [footnotes, setFootnotes] = useState([]);
  const [partnerId, setPartnerId] = useState(undefined);
  const wrapperElement = React.createRef<HTMLDivElement>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [headerConfig, setHeaderConfig] = useState<HeaderResponse>(() => {
    return ssrHeaderData || fetchHeaderConfig(headerConfigDataUrl);
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchServiceClientIdLocal, setSearchServiceClientIdLocal] = useState<string>();
  const [dealerSearchRedirectUrlLocal, setDealerSearchRedirectUrlLocal] = useState<string>();
  const [useOneLayer, setUseOneLayer] = useState(false);
  const [externalDependencies, setExternalDependencies] = useState<ExternalDependenciesType>(() => {
    return {
      loginFeatureAppUrl: `https://featureapps.audi.com/audi-feature-app-user-menu/${
        config?.usermenuFeatureAppVersion || '8'
      }/app.js`,
      miniCartFeatureAppBaseUrl: `https://featureapps.audi.com/audi-feature-app-oneshop-frontend-mini-cart/${
        config?.miniCartFeatureAppVersion || '5.1.0'
      }`,
      miniCartFeatureAppSrc: config?.miniCartFeatureAppSource || 'mini-cart.js',
      oneShopUbffUrl: config?.ubffEndPointUrl || 'https://www.audi.de/oneshop/proxy/ubff',
      searchInputFeatureAppUrl: `https://featureapps.audi.com/audi-feature-app-search-input/${
        config?.searchFeatureAppVersion || '5.0.12'
      }/audi-feature-app-search-input.umd.js`,
      searchResultsFeatureAppUrl: `https://featureapps.audi.com/audi-feature-app-search-results/${
        config?.searchFeatureAppVersion || '5.0.12'
      }/audi-feature-app-search-results.umd.js`,
    };
  });

  const headerConfigWithPartnerId = replacePartnerId(headerConfig, partnerId);

  const useFootnoteReferenceServiceTextParserHook = createUseFootnoteReferenceServiceTextParserHook(
    {
      footnotes,
      referenceServiceManager,
    }
  );

  // retrieve market context service data if available and if needed
  useEffect(() => {
    (async (): Promise<void> => {
      if (typeof audiMarketContextService !== 'undefined') {
        await audiMarketContextService.initMarketContext().then(() => {
          setUseOneLayer(audiMarketContextService.hasEnvScope('ONEHEADER_ONE_LAYER'));
          const usermenuFeatureAppVersion = audiMarketContextService.getContextItem(
            'UsermenuFeatureAppVersion'
          ) as string;
          const miniCartFeatureAppVersion = audiMarketContextService.getContextItem(
            'MiniCartFeatureAppVersion'
          ) as string;
          const miniCartFeatureAppSource = audiMarketContextService.getContextItem(
            'MiniCartFeatureAppSource'
          ) as string;
          const ubffEndPointUrl = audiMarketContextService.getContextItem(
            'UbffEndPointUrl'
          ) as string;
          const searchFeatureAppVersion = audiMarketContextService.getContextItem(
            'SearchFeatureAppVersion'
          ) as string;
          setExternalDependencies((inital) => {
            return {
              loginFeatureAppUrl:
                !config?.usermenuFeatureAppVersion && usermenuFeatureAppVersion
                  ? `https://featureapps.audi.com/audi-feature-app-user-menu/${usermenuFeatureAppVersion}/app.js`
                  : inital.loginFeatureAppUrl,
              miniCartFeatureAppBaseUrl:
                !config?.miniCartFeatureAppVersion && miniCartFeatureAppVersion
                  ? `https://featureapps.audi.com/audi-feature-app-oneshop-frontend-mini-cart/${miniCartFeatureAppVersion}`
                  : inital.miniCartFeatureAppBaseUrl,
              miniCartFeatureAppSrc:
                !config?.miniCartFeatureAppSource && miniCartFeatureAppSource
                  ? miniCartFeatureAppSource
                  : inital.miniCartFeatureAppSrc,
              oneShopUbffUrl:
                !config?.ubffEndPointUrl && ubffEndPointUrl
                  ? ubffEndPointUrl
                  : inital.oneShopUbffUrl,
              searchInputFeatureAppUrl:
                !config?.searchFeatureAppVersion && searchFeatureAppVersion
                  ? `https://featureapps.audi.com/audi-feature-app-search-input/${searchFeatureAppVersion}/audi-feature-app-search-input.umd.js`
                  : inital.searchInputFeatureAppUrl,
              searchResultsFeatureAppUrl:
                !config?.searchFeatureAppVersion && searchFeatureAppVersion
                  ? `https://featureapps.audi.com/audi-feature-app-search-results/${searchFeatureAppVersion}/audi-feature-app-search-results.umd.js`
                  : inital.searchResultsFeatureAppUrl,
            };
          });
        });
      }
    })();
  }, [
    audiMarketContextService,
    config?.miniCartFeatureAppSource,
    config?.miniCartFeatureAppVersion,
    config?.searchFeatureAppVersion,
    config?.ubffEndPointUrl,
    config?.usermenuFeatureAppVersion,
  ]);

  useEffect(() => {
    if (window && window.location.href.indexOf('debug=true') !== -1) {
      headerStateService.setDebugMode(true);
    }
    return (): void => {
      if (window) {
        if (window.location.href.indexOf('debug=true') !== -1) {
          headerStateService.setDebugMode(true);
        } else {
          headerStateService.setDebugMode(false);
        }
      }
    };
  }, []);

  useEffect(() => {
    if (referenceServiceManager) {
      referenceServiceManager.registerCallback((_footnotes: EnumerableFootnote[]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return setFootnotes(_footnotes);
      });
    }
    return (): void => {
      if (referenceServiceManager) {
        referenceServiceManager.removeFootnoteReferences();
      }
    };
  }, []);

  useEffect(() => {
    // always load clientside again, to be sure, not to work with a cached version from SSR
    fetchHeaderConfig(headerConfigDataUrl)
      .then((fetchedHeaderConfigData) => {
        const cleanedResponse = cleanNavigationEntries(fetchedHeaderConfigData);
        setHeaderConfig(cleanedResponse);
        setIsLoading(false);
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error(error));
  }, [headerConfigDataUrl, ssrHeaderData]);

  const [headerState, headerDispatch] = useReducer<React.Reducer<HeaderState, IHeaderAction>>(
    headerReducer,
    {
      activeItem: {
        anchor: null,
        index: -1,
        showSearch: false,
      },
      requestLoadSearchApp: false,
    }
  );

  useEffect(() => {
    if (window && headerState && headerState.requestLoadSearchApp) {
      const searchManagerService: SearchManagerServiceV5 = featureServices[
        'search-manager-service'
      ] as SearchManagerServiceV5;
      if (!searchManagerService) {
        return;
      }

      const {
        setVTPAndDealerSearchConfig,
        setDealerSearchRedirectUrl,
        setClientIdParam,
        setNavigationSearchData,
      } = searchManagerService;

      if (
        headerConfigWithPartnerId?.Search?.OneHeaderSearchClientId &&
        setClientIdParam &&
        searchServiceClientIdLocal !== headerConfigWithPartnerId.Search.OneHeaderSearchClientId
      ) {
        const searchProposalsUrl = window.SETUPS?.get('nemo.url.searchproposals') || '';

        if (searchProposalsUrl !== '') {
          searchManagerService.setSuggestedAndProposalSearchUrl(searchProposalsUrl);
        }

        searchManagerService.events.on(
          SearchEventType.DEBUG_MODE_TOGGLED,
          (debugState: boolean) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (typeof window !== 'undefined' && typeof window['core-global'] !== 'undefined') {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window['core-global'].state.triggerModeChange('debug', debugState);
            }
            headerStateService.setDebugMode(debugState);
          }
        );

        // avoid setting the client id parameter multple times with the same value
        setSearchServiceClientIdLocal(headerConfigWithPartnerId.Search.OneHeaderSearchClientId);

        // communicate client id to search manager service
        setClientIdParam(headerConfigWithPartnerId.Search.OneHeaderSearchClientId);
      }

      if (headerConfigWithPartnerId?.MainNavigation && setNavigationSearchData) {
        setNavigationSearchData({
          MainNavigation: headerConfigWithPartnerId.MainNavigation,
        } as INavigationSearchResponse);
      }

      const vtpConfig = {
        dealerSearch: !!headerConfigWithPartnerId?.Search?.ShowDealerSearchLink,
        newStockCarSearch: !!headerConfigWithPartnerId?.Search?.ShowNewCarsLink,
        usedAndPreOwnedStockCarSearch: !!headerConfigWithPartnerId?.Search?.ShowUsedCarsLink,
      };

      setVTPAndDealerSearchConfig(vtpConfig);

      if (
        headerConfigWithPartnerId?.Search?.DealerSearchLink &&
        setDealerSearchRedirectUrl &&
        dealerSearchRedirectUrlLocal !== headerConfigWithPartnerId.Search.DealerSearchLink
      ) {
        // avoid setting property multiple times with the same value
        setDealerSearchRedirectUrlLocal(headerConfigWithPartnerId.Search.DealerSearchLink);

        // set the direct url for dealer to Search Service Manager Service
        setDealerSearchRedirectUrl(headerConfigWithPartnerId.Search.DealerSearchLink);
      }
    }
  }, [
    headerConfigWithPartnerId,
    setSearchServiceClientIdLocal,
    setDealerSearchRedirectUrlLocal,
    dealerSearchRedirectUrlLocal,
    featureServices,
    headerStateService,
    searchServiceClientIdLocal,
    headerState,
  ]);

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      !enablePartnerIdReplacement ||
      !(window.microkernel && window.microkernel.stateRegistry)
    ) {
      return (): void => {
        // Do nothing
      };
    }

    const partnerCallback = (state: React.SetStateAction<undefined>): void => {
      setPartnerId(state);
    };

    window.microkernel.stateRegistry.subscribeToStore('dbadDealerStore', partnerCallback);

    return (): void => {
      window.microkernel.stateRegistry.unsubscribeFromStore('dbadDealerStore', partnerCallback);
    };
  }, [enablePartnerIdReplacement, setPartnerId]);

  return (
    <AudiHeader
      audiMarketContextService={audiMarketContextService}
      externalFeatureApps={externalDependencies}
      featureAppId={featureAppId}
      headerConfig={headerConfigWithPartnerId}
      headerDispatch={headerDispatch}
      headerState={headerState}
      headerStateService={headerStateService}
      isLoading={isLoading}
      layerManager={layerManager}
      useFootnoteReferenceServiceTextParserHook={useFootnoteReferenceServiceTextParserHook}
      useOneLayer={useOneLayer}
      wrapperElement={wrapperElement}
    />
  );
};

export default AudiHeaderDataManager;
