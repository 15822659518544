import { AudiHeaderStateServiceInterfaceV1 } from '@volkswagen-onehub/audi-header-state-service';
import { ActiveItem } from '../reducers/header-reducer';

enum HeaderActionTypes {
  SetActiveItem = 'SET_ACTIVE_ITEM',
  LoadSearchFA = 'LOAD_SEARCH_FA',
}

interface ActiveItemAction {
  type: HeaderActionTypes;
  payload: {
    activeItem: ActiveItem;
  };
}

export type IHeaderAction = ActiveItemAction;

export const createPreloadAction: () => {
  payload: { activeItem: null };
  type: HeaderActionTypes;
} = () => {
  return {
    payload: { activeItem: null },
    type: HeaderActionTypes.LoadSearchFA,
  };
};

export const createSetActiveItemAction: ({
  anchor,
  index,
  showSearch,
  headerStateService,
}: {
  anchor: HTMLAnchorElement | null;
  index: number;
  showSearch: boolean;
  headerStateService: AudiHeaderStateServiceInterfaceV1;
}) => ActiveItemAction = ({ anchor, index, showSearch, headerStateService }) => {
  headerStateService.setShowSearch(showSearch);
  if (!showSearch && index !== -1) {
    headerStateService.setShowLoginFlyout(false);
  }

  return {
    payload: {
      activeItem: {
        anchor,
        index,
        showSearch,
      },
    },
    type: HeaderActionTypes.SetActiveItem,
  };
};

export const createResetActiveItemAction: (
  headerStateService: AudiHeaderStateServiceInterfaceV1
) => ActiveItemAction = (headerStateService) => {
  return createSetActiveItemAction({
    anchor: null,
    headerStateService,
    index: -1,
    showSearch: false,
  });
};
